import axios from '../utils/axios'

export function getQuestion (params) {
  return axios.get(`/survey/v1/q/invoke.go?id=${params.id}&view=${params.view}`);
}
export function getQuestionUser (params) {
  return axios.post(`/survey/open/user/invoke.go`,params);
}
export function saveQuestion (params) {
  return axios.post('/survey/v1/s/invoke.go', params);
}
export function answerQuestion (params) {
  return axios.get(`/survey/v1/answer/invoke.go?answerId=${params.answerId}`);
}