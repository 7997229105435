
<template>
  <div class="questionnaire" >
    <div v-if="disabled" class="que-overlay" @click.stop.prevent></div>
    <van-empty v-if="loseQuestion" description="问卷已失效！" />
    <div v-else @click.stop="showNameSelect = false">
      <div class="top-title">{{result.surveyName}}</div>
      <div class="surveyDesc">{{result.surveyDesc}}</div>
      <div class="ques-content">
        <van-form @submit="onSubmit" @failed="onFailed" scroll-to-error validate-trigger="onSubmit">
          <div class="ques-item" v-for="(item, index) in result.subjectExtList" :key="index">
            <!-- "type": "0", // 题目类型 0：单选 1：多选 2：问答 "answerShowType": "1", // 答案展示方式 1下拉 2列表 -->
            <div v-if="item.type == '0' && (!item.flagKey || JSON.stringify(checkObjAll[item.flagKey]) == '{}' || (item.flagKey && item.flag) || ((checkSingle[item.subjectId] || checkObj[item.subjectId]) && disabled))">
              <!-- 单选，列表 -->
              <van-field v-if="item.answerShowType == '2'" :label="`${item.subjectNo}、${item.subjectName}`" label-class="question_title" label-width="100%" :rules="[{ required: item.need === '1',validator:remarkValidator, message: '请选择选项' }]" :name="item.subjectId" :required="item.need === '1'">
                <template #input>
                  <van-radio-group v-model="checkSingle[item.subjectId]">
                    <van-cell-group :border="false">
                      <van-cell v-for="v in item.options" :key="v.optionId" clickable :center="true" :border="false" value-class="cell-class" style="min-height: 44px;flex-direction: row;">
                        <van-radio :name="v" @click="subjectHandle(v)" style="min-height:32px">
                          <span style="margin-right:15px">{{v.content}}</span>
                          <input v-if="checkSingle[item.subjectId] && checkSingle[item.subjectId].optionId == v.optionId && v.type == '1'" ref="addInput" v-model="inputObj[v.optionId]" type="text" placeholder="请注明...[必填]" class="question-input">
                        </van-radio>
                      </van-cell>
                    </van-cell-group>
                    <!-- <van-radio :name="v" v-for="v in item.options" :key="v.optionId" @click="subjectHandle(v)" style="padding:5px 0;min-height:32px">
                      <span style="margin-right:15px">{{v.content}}</span>
                      <input v-if="checkSingle[item.subjectId] && checkSingle[item.subjectId].optionId == v.optionId && v.type == '1'" ref="addInput" v-model="inputObj[v.optionId]" type="text" placeholder="请输入" class="question-input">
                    </van-radio> -->
                  </van-radio-group>
                </template>
              </van-field>
              <!-- 单选下拉 -->
              <div v-else class="my-input">
                <van-field v-model="checkObj[item.subjectId]" readonly :error="false" :label="`${item.subjectNo}、${item.subjectName}`" label-class="question_title" label-width="100%" :rules="[{ required: item.need === '1', message: '请选择选项' }]" :name="item.subjectId" :required="item.need === '1'" placeholder="请选择" @click="showPickerClick(item)">
                  <template #right-icon>
                    <van-icon name="arrow-down" />
                  </template>
                </van-field>
              </div>
            </div>
            <!-- 多选列表 -->
            <van-field v-if="item.type == '1' && (!item.flagKey || JSON.stringify(checkObjAll[item.flagKey]) == '{}' || (item.flagKey && item.flag))" :label="`${item.subjectNo}、${item.subjectName}`" label-class="question_title" label-width="100%" :rules="[{ required: item.need === '1',validator:multipleValidator, message: '请选择选项' }]" :name="item.subjectId" :required="item.need === '1'">
              <template #input>
                <van-checkbox-group v-model="checkMultiple[item.subjectId]">
                  <van-cell-group :border="false">
                    <van-cell v-for="v in item.options" :key="v.optionId" clickable @click="item.value = v.optionId" :center="true" :border="false" value-class="cell-class" style="min-height: 44px;flex-direction: row;">
                      <template #value>
                        <van-checkbox :name="v" :label-disabled="true" shape="square" icon-size="18px" @click="subjectHandle(v)">
                          <span style="margin-right:15px">{{v.content}}</span>
                          <input v-if="checkMultiple[item.subjectId] && checkMultiple[item.subjectId].indexOf(v) != -1  && v.type == '1'" ref="addMultiInput" v-model="multiSelect[v.optionId]" type="text" placeholder="请注明...[必填]" class="question-input">
                        </van-checkbox>
                      </template>
                    </van-cell>
                  </van-cell-group>
                </van-checkbox-group>
              </template>
            </van-field>
            <!-- 输入框 -->
            <div v-else-if="item.type == '2'  && (!item.flagKey || JSON.stringify(checkObjAll[item.flagKey]) == '{}' || (item.flagKey && item.flag))" class="my-input">
              <!-- 数字类型 -->
              <template v-if="item.answerType == '4'">
                <van-field v-if="item.verifyList && item.verifyList[0].verifyCode == '1'" maxlength="100" :name="item.subjectId" type="number" :clearable="true" :label="`${item.subjectNo}、${item.subjectName}`" label-class="question_title" label-width="100%" v-model="checkObj[item.subjectId]" :rules="[{ required: item.need === '1',pattern:new RegExp('^\\S{'+item.verifyList[0].min+','+item.verifyList[0].max+'}$'), message: `请输入${item.verifyList[0].min}至${item.verifyList[0].max}位字符` }]" :border="false" autocomplete="off" :required="item.need === '1'" />
                <van-field v-else maxlength="30" :name="item.subjectId" type="number" :label="`${item.subjectNo}、${item.subjectName}`" :clearable="true" label-class="question_title" label-width="100%" v-model="checkObj[item.subjectId]" :rules="[{ required: item.need === '1', message: '请输入内容' }]" :border="false" autocomplete="off" :required="item.need === '1'" />
              </template>
              <div v-else class="name-select">
                <!-- 员工 -->
                <van-field v-if="item.subjectType == '01001' && result.usefor == '1'" @update:model-value="nameUpdate" :name="item.subjectId" maxlength="500" :clearable="true" :label="`${item.subjectNo}、${item.subjectName}`" label-class="question_title" label-width="100%" v-model="checkObj[item.subjectId]" :rules="[{ required: item.need === '1',validator:nameCheckValidator,message: '请输入姓名' }]" :border="false" :required="item.need === '1'" autocomplete="off">
                  <template #extra>
                    <div v-if="showNameSelect" class="select-box">
                      <ul>
                        <li v-for="v in nameOption" :key="v.CODE" v-show="nameOption.length" @click="nameClick(v,item)">{{v.NAME}}&nbsp; ({{v.CODE}})</li>
                        <li v-show="!nameOption.length" class="no-name">未匹配到您的姓名</li>
                      </ul>
                    </div>
                  </template>
                </van-field>
                <!-- subjectType == '01001' 姓名;  usefor == '1' 员工-->
                <!-- 非员工 -->
                <van-field v-else-if="item.subjectType == '01001' && result.usefor != '1'" @update:model-value="nameUpdate" :name="item.subjectId" maxlength="500" :clearable="true" :label="`${item.subjectNo}、${item.subjectName}`" label-class="question_title" label-width="100%" v-model="checkObj[item.subjectId]" :rules="[{ required: item.need === '1',validator:nameValidator,message: '请输入姓名' }]" :border="false" :required="item.need === '1'" autocomplete="off">
                </van-field>
                <van-field v-else-if="item.verifyList && item.verifyList[0].verifyCode == '1'" :name="item.subjectId" maxlength="500" :clearable="true" :label="`${item.subjectNo}、${item.subjectName}`" label-class="question_title" label-width="100%" v-model="checkObj[item.subjectId]" :rules="[{ required: item.need === '1',pattern:new RegExp('^\\S{'+item.verifyList[0].min+','+item.verifyList[0].max+'}$'),message: '请输入内容' }]" :border="false" :required="item.need === '1'" autocomplete="off" />
                <van-field v-else :name="item.subjectId" :clearable="true" maxlength="500" :label="`${item.subjectNo}、${item.subjectName}`" label-class="question_title" label-width="100%" v-model="checkObj[item.subjectId]" :rules="[{ required: item.need === '1',validator:validatorFun(item), message: '请输入内容' }]" :border="false" :required="item.need === '1'" autocomplete="off" />
              </div>
            </div>
          </div>
          <div class="btn-box" v-if="view != '1' && !disabled">
            <van-button round block type="primary" native-type="submit">提交</van-button>
          </div>
        </van-form>
        <van-popup v-model:show="showPicker" position="bottom">
          <van-picker :columns="columns" @confirm="onConfirm" :columns-field-names="customFieldName" @cancel="showPicker = false" />
        </van-popup>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from '@/utils/debounce'
import { Dialog, Toast, } from 'vant'
import { getQuestion, saveQuestion, getQuestionUser, answerQuestion } from '@/service/questionnaire'
import { onMounted, reactive, ref, toRefs } from 'vue'
import { useRouter } from 'vue-router'
export default {
  setup () {
    const router = useRouter()
    const addInput = ref(null)
    const state = reactive({
      answerId: '', // 答案回显id
      disabled: false,
      nameOption: [],
      loseQuestion: false,
      showNameSelect: false,
      surveyId: '',//问卷id
      view: '',//是否可提交 1不可以提交
      showPicker: false,
      checked: false,
      checkObj: {},
      checkMultiple: {},//多选
      checkSingle: {},//单选
      checkObjAll: {},
      inputObj: {}, //单选备注
      multiSelect: {}, //多选备注
      userDate: null,
      columns: [],
      customFieldName: {},
      result: {
        subjectExtList: []
      }
    })
    /**
      "conditionJSON": {
          "ca596a6227dd40e0b82eb9c3fdd670d3": { // 题目id
            "conditionOptions": [
              {
                "showSubjectSet": [
                  "6203c498dc0a43aea89aecf66e7bcab6"
                ],
                "parentValue": "4c175a94a565419494a78e011468aa52" // 当选项为该值时，显示  #showSubjectSet 的题目
              }
            ]
          }
        }
      "departmentName": "事业二部",
      "subjectNo": "1",//题号
      "need": "1", //是否必填 1 必填
      "type": "0", // 题目类型 0：单选 1：多选 2：问答
      "description": null, // 题目补充描述
      "answerShowType": "1", // 答案展示方式 1下拉 2列表
      "subjectId": "ca596a6227dd40e0b82eb9c3fdd670d3", // 题目 id
      "subjectName": "区域",  // 题目内容
      "createDate": null,
      "maxSelect": null, // 最多选择数
      "minSelect": null // 最少选择数
     * **/

    const nameCheckValidator = () => {
      if (!state.userDate) {
        return '请选择正确的姓名'
      } else {
        return true
      }
    }
    const nameValidator = (val) => {
      if (/^[\u4e00-\u9fa5]+(·[\u4e00-\u9fa5]+)*$/.test(val) && val.length > 1 && val.length < 16) {
        return true
      } else {
        return '姓名必须为2到15个汉字'
      }
    }
    const remarkValidator = (val) => {
      let isText = undefined
      if (val) {
        isText = val.type == '1' && !state.inputObj[val.optionId]
      }
      if (isText) {
        return '请输入选项的备注内容'
      } else {
        return true
      }
    }
    const multipleValidator = (val) => {
      let isText = undefined
      if (val) {
        isText = val.find((v) => {
          return v.type == '1' && !state.multiSelect[v.optionId]
        })
      }
      if (isText) {
        return '请输入选项的备注内容'
      } else {
        return true
      }
    }
    const validatorLength = (val) => {
      console.log('Length=' + val)
    }
    const validatorPhone = (val, rules) => {
      console.log('phone=' + val)
      console.log('rules=' + rules)
      console.log(rules)
      if (/1\d{10}/.test(val)) {
        return true
      } else {
        return '请输入正确的手机号'
      }
    }
    const validatorEmail = (val) => {
      let reg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
      console.log('Email=' + val)
      if (reg.test(val)) {
        return true
      } else {
        return '请输入正确的邮箱'
      }
    }
    const validatoreIDCard = (val) => {
      console.log('IDCard=' + val)
      let reg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
      if (reg.test(val)) {
        return true
      } else {
        return '请输入正确的邮箱'
      }
    }
    const validatorFun = (val) => {
      let res = null
      if (val.verifyList) {
        if (val.verifyList[0].verifyCode == '1') {//校验长度
          res = validatorLength
        } else if (val.verifyList[0].verifyCode == '2') {//校验手机号
          res = validatorPhone
        } else if (val.verifyList[0].verifyCode == '3') {// 校验邮箱
          res = validatorEmail
        } else if (val.verifyList[0].verifyCode == '4') {// 校验身份证号
          res = validatoreIDCard
        }
      }
      return res
    }
    // 防抖函数
    const nameUpdate = debounce.debounce((name) => {
      if (!name) {
        state.showNameSelect = false
        state.nameOption = []
        return
      }
      getUser(name)
    })
    const getUser = async (name) => {
      let res = await getQuestionUser({ name })
      state.userDate = null
      if (res.code == 200 && res.result && res.result.length > 0) {
        state.nameOption = res.result
      } else {
        state.nameOption = []
      }
      state.showNameSelect = true
    }
    const nameClick = (v, item) => {
      state.showNameSelect = false
      state.userDate = v
      state.checkObj[item.subjectId] = v.NAME
    }
    const showPickerClick = (obj) => {
      if (state.disabled) {
        return false
      }
      state.columns = obj.options.map((item) => {
        return { text: item.content, ...item }
      })
      state.showPicker = true
    }
    const subjectHandle = (value) => {//根据选项控制题目是否展示
    if (state.disabled) {
      return false
    }
      state.checkObjAll[value.subjectId] = value
      if (value) {
        let conditionJSON = state.result.conditionJSON[value.subjectId]
        if (conditionJSON) {
          state.result.subjectExtList.map((v) => {
            if (v.flagKey) {
              conditionJSON.conditionOptions.map((obj) => {
                if (obj.parentValue == value.optionId) {//对应选项的题目展示
                  obj.showSubjectSet.map((item) => {
                    if (item == v.subjectId) {
                      v.flag = true
                    }
                  })
                } else {
                  obj.showSubjectSet.map((item) => {//其他选项的题目隐藏
                    if (item == v.subjectId) {
                      v.flag = false
                      state.checkObj[v.subjectId] = ''
                      state.checkSingle[v.subjectId] = null
                      state.checkObjAll[v.subjectId] = {}
                    }
                  })
                }
              })
            }
          })
        }
      }
    }
    const onConfirm = (value) => {
      subjectHandle(value)
      state.checkObj[value.subjectId] = value.text
      state.showPicker = false
    }
    // 提交登录或注册表单
    const onSubmit = async (val) => {
      console.log(val)
      console.log(state.checkSingle)
      let items = []
      state.result.subjectExtList.map((item) => {
        if (val && val[item.subjectId] || item.need != '1') {
          let obj = {
            optionType: state.checkObjAll[item.subjectId].type || '',  //#选项类型
            optionId: state.checkObjAll[item.subjectId].optionId || '',// #选项id
            subjectType: item.type, //#题目类型
            subjectId: item.subjectId, // 题目id
            content: '',
            multiSelect: []
          }
          if (item.type == '1') {//多选
            // obj.optionId = state.checkMultiple[item.subjectId].optionId.join()
            let opts = []
            state.checkMultiple[item.subjectId].map((v) => {
              opts.push(v.optionId)
              // if (state.multiSelect[v.optionId]) {
              //   obj.multiSelect.push({ optionId: v.optionId, content: state.multiSelect[v.optionId] })
              // }
              obj.multiSelect.push({ optionId: v.optionId, content: state.multiSelect[v.optionId] || '' })
            })
            obj.optionId = opts
          } else if (item.type == '2') {//输入
            obj.content = state.checkObj[item.subjectId]
          } else { //单选
            if (state.checkObjAll[item.subjectId].type == '1') {//单选此选项对应的类型添加备注内容
              obj.content = state.inputObj[state.checkObjAll[item.subjectId].optionId]
              // if (!obj.content) {
              //   remark.push(item.subjectNo)
              // }
            }
          }
          items.push(obj)
        }
      })
      saveQuestionFun(items)
      // if (nameSelect && state.userDate) {
      //   saveQuestionFun(items)
      // } else {
      //   addInput.value.focus()
      //   Toast(`第${remark[0]}题输入内容为必填项`)
      // }
    }
    const saveQuestionFun = async (items) => {
      let params = {
        "surveyId": state.surveyId, //问卷id
        items
      }
      if (state.userDate) {
        params.user = state.userDate
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      let res = await saveQuestion(params)
      Toast.clear()
      if (res.code == 200) {
        Dialog.alert({
          title: '提示',
          message: '提交成功',
          theme: 'round-button',
          confirmButtonColor: '#1ea0fa'
        }).then(() => {
          router.push({ path: 'QuestionnaireSucess', })
        })
      } else {
        Dialog.alert({
          title: '提示',
          message: res.message || '提交失败！',
          theme: 'round-button',
          confirmButtonColor: '#1ea0fa'
        })
      }
    }
    const getQuestionInfo = async () => {
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      const res = await getQuestion({ id: state.surveyId, view: state.view })
      if (res.code == 200) {
        state.result = res.result
        state.result.subjectExtList.map((v) => {
          if (v.condition) {
            v.flagKey = v.condition.split('-')[0]
          }
          state.checkMultiple[v.subjectId] = []
          state.checkSingle[v.subjectId] = null
          state.checkObj[v.subjectId] = ''
          state.checkObjAll[v.subjectId] = {}
        })
      } else {
        state.loseQuestion = true
      }
      Toast.clear()
    }
    const getAnswerQuestion = async () => {
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      const res = await answerQuestion({ answerId: state.answerId }) // '3a5919e6f6dc4b83b9cca382a5bc7302'
      if (res.code == 200) {
        state.result = res.result
        state.result.subjectExtList.map((v) => {
          if (v.condition) {
            v.flagKey = v.condition.split('-')[0]
          }
          let optionsItem = {}
          if(v.ygAnswerDetail) {
            if (v.type == '0') { // "type": "0", // 题目类型 0：单选 1：多选
              optionsItem = v.options.find((a) => {
                return a.optionId ==  v.ygAnswerDetail.optionId
              })
              if (v.answerShowType == '2') {
                state.checkSingle[v.subjectId] = optionsItem
                if (optionsItem.type == '1') {//单选此选项对应的类型添加备注内容
                    state.inputObj[optionsItem.optionId] = v.ygAnswerDetail.content || ''
                }
              } else { //
                state.checkObj[v.subjectId] = optionsItem.content
              }
            } else if (v.type == '1') {
               optionsItem = v.options.filter((a) => {
                return v.ygAnswerDetail.optionId.indexOf(a.optionId) != -1
              })
              state.checkMultiple[v.subjectId] = optionsItem
              let optionContent = null
              if (v.ygAnswerDetail.optionContent) {
                optionContent = JSON.parse(v.ygAnswerDetail.optionContent)
              }
              
              optionsItem.map((a) => {
                if (a.type == '1') {
                  let content = optionContent.find((b) => {
                    return a.optionId == b.optionId
                  })
                  if (content) {
                    state.multiSelect[a.optionId] = content.content
                  }
                }
              })
            } else {
              state.checkObj[v.subjectId] = v.ygAnswerDetail.content
            }
          }
          // state.checkObjAll[v.subjectId] = {}
        })
      } else {
        state.loseQuestion = true
      }
      Toast.clear()
    }
    onMounted(() => {
      state.surveyId = getUrlValue('id') || '' //815f7fa1bf5e4d96bcdac8ebd8cab7b9
      state.answerId = getUrlValue('answerId') || '' //815f7fa1bf5e4d96bcdac8ebd8cab7b9
      state.view = getUrlValue('view') || ''
      if (state.answerId) {
        state.disabled = true
        getAnswerQuestion() //答案回显
      } else {
        state.disabled = false
        getQuestionInfo()
      }
      // getQuestionInfo()
      // getAnswerQuestion()
    })
    const onFailed = (err) => {
      console.log(err)
      console.log(state.checkObj)
      Toast(err.errors[0].message)
    }
    const getUrlValue = (key) => {
      let returnValue = "";
      let href = location.href;
      let s = href.split(key + "=")[1] || "";
      if (s.indexOf("&") != -1) {
        returnValue = s.split("&")[0];
      } else if (s.indexOf("/") != -1) {
        returnValue = s.split("/")[0];
      } else {
        returnValue = s;
      }
      return returnValue;
    }

    return {
      ...toRefs(state),
      addInput,
      onSubmit,
      onFailed,
      onConfirm,
      showPickerClick,
      subjectHandle,
      getUrlValue,
      nameCheckValidator,
      nameValidator,
      remarkValidator,
      multipleValidator,
      validatorLength,
      validatorPhone,
      validatorEmail,
      validatoreIDCard,
      validatorFun,
      nameClick,
      nameUpdate,
      getQuestionInfo,
      getAnswerQuestion
    }
  }
}
</script>

<style >
.que-overlay {
  position: absolute;
  height: 100%;
  width: 100vw;
  top: 0;
  bottom: 0;
  z-index: 100;
}
.question_title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 6px;
  display: block;
  word-wrap: break-word;
  color: #2c3e50;
}
.cell-class {
  overflow: visible;
}
</style>
<style lang="less" scoped>
// /deep/.van-radio__label {
//   display: flex;
//   flex-wrap: wrap;
// }
.name-select {
  z-index: 10;
}
.select-box {
  position: relative;
  ul {
    list-style: none;
    position: absolute;
    max-height: 100px;
    overflow: scroll;
    z-index: 10;
    width: 100%;
    background: #fff;
    padding: 4px 0;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding-top: 5px;
    li {
      padding: 0 15px;
      border-radius: 4px;
    }
    li:hover {
      background: #e6f7ff;
    }
    .no-name {
      color: #999;
      text-align: center;
      font-size: 14px;
      background: #e6f7ff;
    }
  }
}
/deep/.van-cell {
  flex-direction: column;
  overflow: visible;
  padding-bottom: 0;
}
/deep/.my-input .van-field__body {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding-right: 10px;
}
/deep/.my-input .van-field__control {
  padding: 5px 10px;
}
.question_title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 6px;
  display: block;
  word-wrap: break-word;
}
.questionnaire {
  max-width: 768px;
  margin: 0 auto;
  padding-bottom: 20px;
  background: #fff;
  position: relative;
  .ques-item {
    margin-bottom: 10px;
  }
  .top-title {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 26px 10px 12px;
    color: #1ea0fa;
    line-height: 32px;
    font-weight: bold;
    text-align: center;
    font-size: 20px;
  }
  .surveyDesc {
    display: block;
    padding: 10px 0 26px;
    margin: 0 10px 16px;
    clear: both;
    border-bottom: 1px dashed #ccc;
    font-size: 14px;
    line-height: 24px;
  }
  .ques-content {
    font-size: 14px;
  }
  .btn-box {
    padding: 10px 20px;
    margin-top: 20px;
  }
}

.question-input {
  -web-kit-appearance: none;
  -moz-appearance: none;
  color: #6a6f77;
  outline: 0;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 5px 10px;
}
input[type="text"] {
  box-sizing: border-box;
  text-align: left;
  border-radius: 4px;
  border: 1px solid #c8cccf;
  color: #6a6f77;
  -web-kit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  text-decoration: none;
}
// input[type="text"]:focus {
//   border: 1px solid #1989fa;
// }
</style>
