
<template>
  <div>
    <div class="sucess-content">
      <div>
        <van-icon name="checked" color="#07c160" size="80" />
      </div>
      <div class="sucess-text">提交成功</div>
      <div class="btn">
        <!-- <van-button round block type="primary" @click="router.back()">关闭</van-button> -->
        <van-button round block type="primary" @click="$router.go(-1)">返回</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
export default {
  setup () {
    const router = useRouter()
    const close = () => {
      window.location.href = "about:blank";
      window.close();
    }
    return {
      close,
      router
    }
  }
}
</script>
<style lang="less" scoped>
.sucess-content {
  max-width: 768px;
  margin: 0 auto;
  padding-top: 80px;
  text-align: center;
  background: #fff;
}
.sucess-text {
  font-size: 20px;
  color: #333;
  line-height: 80px;
}
.btn {
  padding: 0 20px;
}
</style>
